
    import __i18nConfig from '@next-translate-root/i18n'
    import __appWithI18n from 'next-translate/appWithI18n'
    import Image from 'next/image';
import Link from "next/link";
import { Spinner } from "reactstrap";
import BaseLayout from "@components/BaseLayout";
import ForwardingPage from "@components/common/ForwardingPage";
import SponsorItem from "@components/common/SponsorComponent";
import TranslatedHtml from "@components/common/TranslatedHtml";
import { withDynamicNamespaces } from "@components/hoc/withDynamicNamespaces";
import { NamespaceShortcut, useDynamicTranslation } from "@services/i18n";
import { useCurrentProgram } from "@services/pageHelpers";
import { Routes } from "@services/routes";
import { SINN_PROTOTYPE_CLIENT_CONFIG_USED } from "config";
const usedNamespaces: NamespaceShortcut[] = ["common", "program-operations"];
/**
 * this page is the front page of the app
 */
const FrontPage: React.FC = () => {
    const t = useDynamicTranslation();
    const { currentProgram, programRequest } = useCurrentProgram();
    if (SINN_PROTOTYPE_CLIENT_CONFIG_USED) {
        return <ForwardingPage targetUrl={Routes.Login}/>;
    }
    return <BaseLayout pageTitle={t("common", "page.index.title")} isFrontPage={true}>
    <div className="frontpage-content">
      <h1 role="heading" aria-level={2} className="frontpage-title">{t("common", "page.index.heading")}</h1>
      <span className="frontpage-subtitle">
        <TranslatedHtml content={t("common", "page.index.quote")}/>
      </span>
      {programRequest?.isLoading
            ? <Spinner />
            : currentProgram
                ? <Link href={Routes.Marketplace} className="btn btn-frontpage">
            <span className="title">{t("common", "page.index.buttonTitle")}</span>
            {t("common", "page.index.button")}
          </Link>
                : <div className="btn btn-frontpage">{t("program-operations", "noProgram")}</div>}
    </div>
    <div className="sponsors">
      <SponsorItem>
        <Image src="/assets/img/sinn-sachsen/EU-Logo.png" alt="Sponsored by EU" width="175" height="35" priority/>
      </SponsorItem>
    </div>
  </BaseLayout>;
};
const __Next_Translate__Page__191743ac6d4__ = withDynamicNamespaces(FrontPage, usedNamespaces);

    export default __appWithI18n(__Next_Translate__Page__191743ac6d4__, {
      ...__i18nConfig,
      isLoader: true,
      skipInitialProps: false,
      loadLocaleFrom: __i18nConfig && __i18nConfig.loadLocaleFrom || ((l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default)),
    });
  