import { Col, Row, Spinner } from "reactstrap"

import BaseLayout from "@components/BaseLayout"
import { withDynamicNamespaces } from "@components/hoc/withDynamicNamespaces"
import { NamespaceShortcut, useDynamicTranslation } from "@services/i18n"

const usedNamespaces: NamespaceShortcut[] = ["goto"]

interface IProps {
  /**
   * Target url of the forwarding process.
   * If target is null or undefined there will just be the page without a forwarding.
   * */
  targetUrl: string
}

/**
 * This component forwards the user to a given url while showing him a simple page without
 * relevant content, e.g. to avoid the user sees fragments of the original page while data is loaded
 * from the API, when waiting for the forward process to take effect.
 */
const ForwardingPage: React.FC<IProps> = (props: IProps) => {
  const { targetUrl } = props
  const t = useDynamicTranslation()

  if (targetUrl) {
    window?.location.assign(targetUrl)
  }

  return <BaseLayout pageTitle={t('goto', 'forwarding')}>
    <Row className='text-center'>
      <Col>
        <h3>{t('goto', 'forwarding')}</h3>
        <Spinner className='text-center' />
      </Col>
    </Row>
  </BaseLayout>
}

export default withDynamicNamespaces<IProps>(ForwardingPage, usedNamespaces)